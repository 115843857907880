<template>
  <v-container>
    <v-banner
      class="mx-auto"
      max-width="800"
      color="deep-orange-accent-4"
      icon="mdi-pencil"
      lines="one"
    >
      <v-banner-text>
        EDITAR {{ person?.name }} {{ person?.last_name }}
        {{ person?.mother_lastname }}
      </v-banner-text>

      <template v-slot:actions>
        <v-btn @click="showDeleteDialog = true" variant="tonal">ELIMINAR</v-btn>
        <v-btn @click="updatePerson">GUARDAR CAMBIOS</v-btn>
      </template>
    </v-banner>
    <v-card class="mx-auto" max-width="800" flat elevation="0">
      <v-card-text>
        <v-form v-if="person" @submit.prevent="updatePerson">
          <v-row>
            <v-col cols="12" md="3">
              <v-img
                :aspect-ratio="1"
                class="bg-white"
                :src="getUserPhotoUrl(person.photo.filename)"
                width="400"
                height="227"
                cover
              ></v-img>
            </v-col>
            <v-col cols="12" md="9">
              <v-row dense>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="person.code"
                    label="Código"
                    required
                    @input="toUpperCase('code')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="person.name"
                    label="Nombre"
                    required
                    @input="toUpperCase('name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="person.last_name"
                    label="Apellido"
                    required
                    @input="toUpperCase('last_name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="person.mother_lastname"
                    label="Apellido Materno"
                    @input="toUpperCase('mother_lastname')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="person.number_document"
                    label="Número de Documento"
                    required
                    @input="toUpperCase('number_document')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="person.typeDocument.id"
                    :items="typeDocuments"
                    item-title="name"
                    item-value="id"
                    label="Tipo de Documento"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="person.bloodType.id"
                    :items="bloodTypes"
                    item-title="name"
                    item-value="id"
                    label="Tipo de Sangre"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="person.jobTitle.id"
                    :items="jobTitles"
                    item-title="name"
                    item-value="id"
                    label="Cargo"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                    v-model="person.status"
                    label="Estado"
                    :true-value="true"
                    :false-value="false"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
            
          </v-row>
          <ImageCropper @imageUploaded="onImageUploaded" />
          <!-- Panel de Teléfonos -->
          <v-card flat class="mt-4">
            <v-card-title>Teléfonos</v-card-title>
            <v-card-text>
              <v-row v-for="(phone, index) in phones" :key="index">
                <v-col cols="4">
                  <v-text-field
                    v-model="phone.code_country"
                    label="Código de País"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="phone.phone"
                    label="Teléfono"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn icon @click="removePhone(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-btn @click="addPhone">Agregar Teléfono</v-btn>
            </v-card-text>
          </v-card>

          <!-- Panel de Correos -->
          <v-card flat class="mt-4">
            <v-card-title>Correos</v-card-title>
            <v-card-text>
              <v-row v-for="(mail, index) in mails" :key="index">
                <v-col cols="10">
                  <v-text-field
                    v-model="mail.email"
                    label="Correo"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn icon @click="removeMail(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-btn @click="addMail">Agregar Correo</v-btn>
            </v-card-text>
          </v-card>

          
        </v-form>
        <v-alert v-else type="error">Cargando datos de la persona...</v-alert>
      </v-card-text>
    </v-card>

    <!-- Dialogo de confirmación para eliminar -->
    <v-dialog v-model="showDeleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Confirmar Eliminación</v-card-title>
        <v-card-text>¿Estás seguro de que deseas eliminar esta persona?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showDeleteDialog = false">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="confirmDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import api from "@/services/api";
import { useRouter, useRoute } from "vue-router";
import { Person } from "@/types";
import ImageCropper from "@/components/ImageCropper.vue";

export default defineComponent({
  name: "EditPerson",
  components: {
    ImageCropper,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const person = ref<Person | null>(null);
    const photo = ref<File | null>(null);
    const typeDocuments = ref([]);
    const bloodTypes = ref([]);
    const jobTitles = ref([]);
    const phones = ref([{ code_country: '', phone: '', status: true }]);
    const mails = ref([{ email: '', status: true }]);
    const showDeleteDialog = ref(false);

    const fetchPerson = async () => {
      try {
        const response = await api.get(`/peoples/${route.params.id}`);
        person.value = response.data;
        phones.value = response.data.phones || [];
        mails.value = response.data.mails.map((mail: any) => ({
          email: mail.address,
          status: mail.status,
        })) || [];
      } catch (error) {
        console.error("Error fetching person:", error);
      }
    };

    const fetchTypeDocuments = async () => {
      try {
        const response = await api.get("/type-documents");
        typeDocuments.value = response.data;
      } catch (error) {
        console.error("Error fetching type documents:", error);
      }
    };

    const fetchBloodTypes = async () => {
      try {
        const response = await api.get("/blood-types");
        bloodTypes.value = response.data;
      } catch (error) {
        console.error("Error fetching blood types:", error);
      }
    };

    const fetchJobTitles = async () => {
      try {
        const response = await api.get("/job-titles");
        jobTitles.value = response.data;
      } catch (error) {
        console.error("Error fetching job titles:", error);
      }
    };

    const onImageUploaded = (imageId: string) => {
      if (person.value) {
        person.value.photo.id = imageId;
      }
    };

    const getUserPhotoUrl = (filename: any) => {
      const baseUrl = process.env.VUE_APP_API_URL || 'https://api.v1.funaprodedh.com';
      return `${baseUrl}uploads/${filename}`;
    };

    const toUpperCase = (field: keyof Person) => {
      if (person.value && typeof person.value[field] === 'string') {
        person.value[field] = (person.value[field] as string).toUpperCase() as never;
      }
    };

    const addPhone = () => {
      phones.value.push({ code_country: '', phone: '', status: true });
    };

    const removePhone = (index: number) => {
      phones.value.splice(index, 1);
    };

    const addMail = () => {
      mails.value.push({ email: '', status: true });
    };

    const removeMail = (index: number) => {
      mails.value.splice(index, 1);
    };

    const updatePerson = async () => {
      if (!person.value) {
        console.error("Person data is not loaded");
        return;
      }

      try {
        const data = {
          typeDocument: { id: person.value.typeDocument.id },
          number_document: person.value.number_document,
          name: person.value.name,
          last_name: person.value.last_name,
          mother_lastname: person.value.mother_lastname,
          code: person.value.code,
          photo: { id: person.value.photo.id },
          bloodType: { id: person.value.bloodType.id },
          jobTitle: { id: person.value.jobTitle.id },
          status: person.value.status,
          phones: phones.value,
          mails: mails.value.map(mail => ({
            address: mail.email,
            status: mail.status,
          })),
        };

        await api.put(`/peoples/${person.value.id}`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        router.push("/dashboard/control-personas");
      } catch (error) {
        console.error("Error updating person:", error);
      }
    };

    const deletePerson = async () => {
      if (!person.value) {
        console.error("Person data is not loaded");
        return;
      }

      try {
        await api.delete(`/peoples/${person.value.id}`);
        router.push("/dashboard/control-personas");
      } catch (error) {
        console.error("Error deleting person:", error);
      }
    };

    const confirmDelete = () => {
      showDeleteDialog.value = false;
      deletePerson();
    };

    onMounted(() => {
      fetchPerson();
      fetchTypeDocuments();
      fetchBloodTypes();
      fetchJobTitles();
    });

    return {
      person,
      photo,
      typeDocuments,
      bloodTypes,
      jobTitles,
      phones,
      mails,
      updatePerson,
      deletePerson,
      confirmDelete,
      getUserPhotoUrl,
      showDeleteDialog,
      onImageUploaded,
      toUpperCase,
      addPhone,
      removePhone,
      addMail,
      removeMail,
    };
  },
});
</script>