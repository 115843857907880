import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: "mx-auto card-main",
        "max-width": "800",
        flat: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("span", { class: "text-h5" }, "Datos del defensor", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (_ctx.person)
                ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "3",
                        class: "text-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_img, {
                            src: _ctx.getUserPhotoUrl(_ctx.person.photo.filename),
                            alt: "Foto de la persona",
                            "max-width": "300"
                          }, null, 8, ["src"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "9" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list, {
                                    class: "bg-none",
                                    dense: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[1] || (_cache[1] = [
                                                  _createTextVNode("Nombre")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_list_item_subtitle, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.person.name), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_list_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[2] || (_cache[2] = [
                                                  _createTextVNode("Apellido")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_list_item_subtitle, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.person.last_name), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_list_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                                  _createTextVNode("Apellido Materno")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_list_item_subtitle, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.person.mother_lastname), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_list_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                                  _createTextVNode("Número de Documento")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_list_item_subtitle, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.person.typeDocument.code) + " V-" + _toDisplayString(_ctx.person.number_document), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_list_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[5] || (_cache[5] = [
                                                  _createTextVNode("Tipo de Sangre")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_list_item_subtitle, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.person.bloodType.name), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_list_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                  _createTextVNode("Telefonos")
                                                ])),
                                                _: 1
                                              }),
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.person.phones, (phone) => {
                                                return (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                                                  key: phone.id
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(phone.code_country) + " " + _toDisplayString(phone.phone), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              }), 128))
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_list_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                  _createTextVNode("Correos")
                                                ])),
                                                _: 1
                                              }),
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.person.mails, (mail) => {
                                                return (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                                                  key: mail.id
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(mail.address), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              }), 128))
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_list_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                  _createTextVNode("Estado")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_list_item_subtitle, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_chip, {
                                                    class: "mt-5",
                                                    size: "x-large",
                                                    color: _ctx.person.status ? 'green' : 'red',
                                                    variant: "flat"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.person.status ? "Activo" : "Inactivo"), 1)
                                                    ]),
                                                    _: 1
                                                  }, 8, ["color"])
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list, {
                                    class: "bg-none",
                                    dense: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                  _createTextVNode("Cargo")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_list_item_subtitle, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.person.jobTitle.name), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_list_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[10] || (_cache[10] = [
                                                  _createTextVNode("Código")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_list_item_subtitle, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.person.code), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_list_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[11] || (_cache[11] = [
                                                  _createTextVNode("RIF")
                                                ])),
                                                _: 1
                                              }),
                                              _cache[12] || (_cache[12] = _createElementVNode("p", null, "J-40684224-9", -1))
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                                  _createTextVNode("Dirección")
                                                ])),
                                                _: 1
                                              }),
                                              _cache[14] || (_cache[14] = _createElementVNode("p", null, [
                                                _createElementVNode("b", null, "FUNAPRODEDH:"),
                                                _createTextVNode(" Calle Sta. Eduviges Casa Nro 16, Sector Santa Eduviges II San Joaquin, Carabobo Zona Postal 2018")
                                              ], -1))
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[15] || (_cache[15] = [
                                                  _createTextVNode("Telefonos")
                                                ])),
                                                _: 1
                                              }),
                                              _cache[16] || (_cache[16] = _createElementVNode("p", null, [
                                                _createElementVNode("b", null, "FUNAPRODEDH:"),
                                                _createTextVNode("0414-2480826 / 0414-4424739 / 0414-4283339")
                                              ], -1))
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_list_item_content, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, null, {
                                                default: _withCtx(() => _cache[17] || (_cache[17] = [
                                                  _createTextVNode("Presidente Ejecutivo")
                                                ])),
                                                _: 1
                                              }),
                                              _cache[18] || (_cache[18] = _createElementVNode("p", null, "Abg. Ramon León", -1))
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_v_alert, {
                    key: 1,
                    type: "error"
                  }, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode("Cargando datos de la persona...")
                    ])),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}