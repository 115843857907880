<template>
  <v-container>
    
    <v-form @submit.prevent="addPerson">
      <v-card>
        <v-card-text>
          <v-autocomplete
        v-model="person.typeDocument.id"
        :items="formattedTypeDocuments"
        item-title="name"
        item-value="id"
        label="Tipo de Documento"
        required
      ></v-autocomplete>
      <v-text-field
        v-model="person.number_document"
        label="Número de Documento"
        required
        @input="toUpperCase('number_document')"
      ></v-text-field>
      <v-text-field
        v-model="person.name"
        label="Nombre"
        required
        @input="toUpperCase('name')"
      ></v-text-field>
      <v-text-field
        v-model="person.last_name"
        label="Apellido"
        required
        @input="toUpperCase('last_name')"
      ></v-text-field>
      <v-text-field
        v-model="person.mother_lastname"
        label="Apellido Materno"
        @input="toUpperCase('mother_lastname')"
      ></v-text-field>
      <v-text-field
        v-model="person.code"
        label="Código"
        required
        @input="toUpperCase('code')"
      ></v-text-field>
      <v-autocomplete
        v-model="person.bloodType.id"
        :items="formattedBloodTypes"
        item-title="name"
        item-value="id"
        label="Tipo de Sangre"
        required
      ></v-autocomplete>
      <v-autocomplete
        v-model="person.jobTitle.id"
        :items="formattedJobTitles"
        item-title="name"
        item-value="id"
        label="Cargo"
        required
      ></v-autocomplete>
      <image-cropper @imageUploaded="onImageUploaded" />
      

        </v-card-text>
      </v-card>
      
      <!-- Panel de Teléfonos -->
      <v-card class="mt-4">
        <v-card-title>Teléfonos</v-card-title>
        <v-card-text>
          <v-row v-for="(phone, index) in phones" :key="index">
            <v-col cols="4">
              <v-text-field
                v-model="phone.code_country"
                label="Código de País"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="phone.phone"
                label="Teléfono"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="removePhone(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-btn @click="addPhone">Agregar Teléfono</v-btn>
        </v-card-text>
      </v-card>

      <!-- Panel de Correos -->
      <v-card class="mt-4">
        <v-card-title>Correos</v-card-title>
        <v-card-text>
          <v-row v-for="(mail, index) in mails" :key="index">
            <v-col cols="10">
              <v-text-field
                v-model="mail.address"
                label="Correo"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="removeMail(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-btn @click="addMail">Agregar Correo</v-btn>
        </v-card-text>
      </v-card>

      <v-btn type="submit" color="primary">Agregar</v-btn>
    </v-form>

    <!-- Dialogo de error -->
    <v-dialog v-model="showErrorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Error</v-card-title>
        <v-card-text>{{ errorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="showErrorDialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import api from '@/services/api';
import { useRouter } from 'vue-router';
import { TypeDocument, BloodType, JobTitle, Person, Phone, Mail } from '@/types';
import { useToast } from 'vue-toastification';
import ImageCropper from '@/components/ImageCropper.vue';

export default defineComponent({
  name: 'AddPerson',
  components: {
    ImageCropper,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();
    const person = ref<Person>({
      id: null,
      typeDocument: { id: null, code: '', name: '', description: '', status: true, created_at: '', updated_at: '' },
      number_document: '',
      name: '',
      last_name: '',
      mother_lastname: '',
      code: '',
      bloodType: { id: null, code: '', name: '', description: '', status: true, created_at: '', updated_at: '' },
      jobTitle: { id: null, code: '', name: '', description: '', status: true, created_at: '', updated_at: '' },
      photo: { id: null, filename: '', name: '', type: '', size: 0, status: true, created_at: '', updated_at: '' },
      status: true,
      created_at: '',
      updated_at: '',
      phones: [{ code_country: '', phone: '', status: true }],
      mails: [{ address: '', status: true }],
    });
    const typeDocuments = ref<TypeDocument[]>([]);
    const bloodTypes = ref<BloodType[]>([]);
    const jobTitles = ref<JobTitle[]>([]);
    const phones = ref<Phone[]>([{ code_country: '', phone: '', status: true }]);
    const mails = ref<Mail[]>([{ address: '', status: true }]);
    const showErrorDialog = ref(false);
    const errorMessage = ref('');

    const formattedTypeDocuments = computed(() =>
      typeDocuments.value.map(doc => ({
        name: doc.name,
        abbr: doc.code,
        id: doc.id,
      }))
    );

    const formattedBloodTypes = computed(() =>
      bloodTypes.value.map(type => ({
        name: type.name,
        abbr: type.code,
        id: type.id,
      }))
    );

    const formattedJobTitles = computed(() =>
      jobTitles.value.map(title => ({
        name: title.name,
        abbr: title.code,
        id: title.id,
      }))
    );

    const fetchTypeDocuments = async () => {
      try {
        const response = await api.get('/type-documents');
        typeDocuments.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const fetchBloodTypes = async () => {
      try {
        const response = await api.get('/blood-types');
        bloodTypes.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const fetchJobTitles = async () => {
      try {
        const response = await api.get('/job-titles');
        jobTitles.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const onImageUploaded = (photoId: string) => {
      person.value.photo.id = photoId;
    };

    const toUpperCase = (field: keyof Person) => {
      if (person.value && typeof person.value[field] === 'string') {
        person.value[field] = (person.value[field] as string).toUpperCase() as never;
      }
    };

    const addPhone = () => {
      phones.value.push({ code_country: '', phone: '', status: true });
    };

    const removePhone = (index: number) => {
      phones.value.splice(index, 1);
    };

    const addMail = () => {
      mails.value.push({ address: '', status: true });
    };

    const removeMail = (index: number) => {
      mails.value.splice(index, 1);
    };

    const addPerson = async () => {
      if (!person.value.typeDocument.id || !person.value.number_document || !person.value.name || !person.value.last_name || !person.value.code || !person.value.bloodType.id || !person.value.jobTitle.id || !person.value.photo.id) {
        errorMessage.value = 'Por favor, complete todos los campos obligatorios.';
        showErrorDialog.value = true;
        return;
      }

      const data = {
        typeDocument: { id: person.value.typeDocument.id },
        number_document: person.value.number_document,
        name: person.value.name,
        last_name: person.value.last_name,
        mother_lastname: person.value.mother_lastname,
        code: person.value.code,
        photo: { id: person.value.photo.id },
        bloodType: { id: person.value.bloodType.id },
        jobTitle: { id: person.value.jobTitle.id },
        status: person.value.status,
        phones: phones.value,
        mails: mails.value,
      };

      try {
        await api.post('/peoples', data);
        toast.success('Persona agregada exitosamente');
        router.push('/dashboard/control-personas');
      } catch (err) {
        const error = err as { response?: { data?: { message?: string } }; message: string };
        toast.error(`Error al agregar persona: ${error.response?.data?.message || error.message}`);
        console.error(error);
      }
    };

    onMounted(() => {
      fetchTypeDocuments();
      fetchBloodTypes();
      fetchJobTitles();
    });

    return {
      person,
      formattedTypeDocuments,
      formattedBloodTypes,
      formattedJobTitles,
      phones,
      mails,
      addPerson,
      onImageUploaded,
      showErrorDialog,
      errorMessage,
      toUpperCase,
      addPhone,
      removePhone,
      addMail,
      removeMail,
    };
  },
});
</script>