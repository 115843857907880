import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_banner_text = _resolveComponent("v-banner-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_banner = _resolveComponent("v-banner")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ImageCropper = _resolveComponent("ImageCropper")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_banner, {
        class: "mx-auto",
        "max-width": "800",
        color: "deep-orange-accent-4",
        icon: "mdi-pencil",
        lines: "one"
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDeleteDialog = true)),
            variant: "tonal"
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode("ELIMINAR")
            ])),
            _: 1
          }),
          _createVNode(_component_v_btn, { onClick: _ctx.updatePerson }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createTextVNode("GUARDAR CAMBIOS")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_banner_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" EDITAR " + _toDisplayString(_ctx.person?.name) + " " + _toDisplayString(_ctx.person?.last_name) + " " + _toDisplayString(_ctx.person?.mother_lastname), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card, {
        class: "mx-auto",
        "max-width": "800",
        flat: "",
        elevation: "0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (_ctx.person)
                ? (_openBlock(), _createBlock(_component_v_form, {
                    key: 0,
                    onSubmit: _withModifiers(_ctx.updatePerson, ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "3"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_img, {
                                "aspect-ratio": 1,
                                class: "bg-white",
                                src: _ctx.getUserPhotoUrl(_ctx.person.photo.filename),
                                width: "400",
                                height: "227",
                                cover: ""
                              }, null, 8, ["src"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "9"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, { dense: "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        modelValue: _ctx.person.code,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.person.code) = $event)),
                                        label: "Código",
                                        required: "",
                                        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toUpperCase('code')))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "6"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        modelValue: _ctx.person.name,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.person.name) = $event)),
                                        label: "Nombre",
                                        required: "",
                                        onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toUpperCase('name')))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        modelValue: _ctx.person.last_name,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.person.last_name) = $event)),
                                        label: "Apellido",
                                        required: "",
                                        onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toUpperCase('last_name')))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        modelValue: _ctx.person.mother_lastname,
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.person.mother_lastname) = $event)),
                                        label: "Apellido Materno",
                                        onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.toUpperCase('mother_lastname')))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        modelValue: _ctx.person.number_document,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.person.number_document) = $event)),
                                        label: "Número de Documento",
                                        required: "",
                                        onInput: _cache[10] || (_cache[10] = ($event: any) => (_ctx.toUpperCase('number_document')))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_autocomplete, {
                                        modelValue: _ctx.person.typeDocument.id,
                                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.person.typeDocument.id) = $event)),
                                        items: _ctx.typeDocuments,
                                        "item-title": "name",
                                        "item-value": "id",
                                        label: "Tipo de Documento",
                                        required: ""
                                      }, null, 8, ["modelValue", "items"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_autocomplete, {
                                        modelValue: _ctx.person.bloodType.id,
                                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.person.bloodType.id) = $event)),
                                        items: _ctx.bloodTypes,
                                        "item-title": "name",
                                        "item-value": "id",
                                        label: "Tipo de Sangre",
                                        required: ""
                                      }, null, 8, ["modelValue", "items"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_autocomplete, {
                                        modelValue: _ctx.person.jobTitle.id,
                                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.person.jobTitle.id) = $event)),
                                        items: _ctx.jobTitles,
                                        "item-title": "name",
                                        "item-value": "id",
                                        label: "Cargo",
                                        required: ""
                                      }, null, 8, ["modelValue", "items"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_switch, {
                                        modelValue: _ctx.person.status,
                                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.person.status) = $event)),
                                        label: "Estado",
                                        "true-value": true,
                                        "false-value": false
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ImageCropper, { onImageUploaded: _ctx.onImageUploaded }, null, 8, ["onImageUploaded"]),
                      _createVNode(_component_v_card, {
                        flat: "",
                        class: "mt-4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, null, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createTextVNode("Teléfonos")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phones, (phone, index) => {
                                return (_openBlock(), _createBlock(_component_v_row, { key: index }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "4" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          modelValue: phone.code_country,
                                          "onUpdate:modelValue": ($event: any) => ((phone.code_country) = $event),
                                          label: "Código de País",
                                          required: ""
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, { cols: "6" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          modelValue: phone.phone,
                                          "onUpdate:modelValue": ($event: any) => ((phone.phone) = $event),
                                          label: "Teléfono",
                                          required: ""
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, { cols: "2" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          icon: "",
                                          onClick: ($event: any) => (_ctx.removePhone(index))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[20] || (_cache[20] = [
                                                _createTextVNode("mdi-delete")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128)),
                              _createVNode(_component_v_btn, { onClick: _ctx.addPhone }, {
                                default: _withCtx(() => _cache[21] || (_cache[21] = [
                                  _createTextVNode("Agregar Teléfono")
                                ])),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card, {
                        flat: "",
                        class: "mt-4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, null, {
                            default: _withCtx(() => _cache[22] || (_cache[22] = [
                              _createTextVNode("Correos")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mails, (mail, index) => {
                                return (_openBlock(), _createBlock(_component_v_row, { key: index }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "10" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          modelValue: mail.email,
                                          "onUpdate:modelValue": ($event: any) => ((mail.email) = $event),
                                          label: "Correo",
                                          required: ""
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, { cols: "2" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          icon: "",
                                          onClick: ($event: any) => (_ctx.removeMail(index))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[23] || (_cache[23] = [
                                                _createTextVNode("mdi-delete")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128)),
                              _createVNode(_component_v_btn, { onClick: _ctx.addMail }, {
                                default: _withCtx(() => _cache[24] || (_cache[24] = [
                                  _createTextVNode("Agregar Correo")
                                ])),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onSubmit"]))
                : (_openBlock(), _createBlock(_component_v_alert, {
                    key: 1,
                    type: "error"
                  }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode("Cargando datos de la persona...")
                    ])),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showDeleteDialog,
        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.showDeleteDialog) = $event)),
        "max-width": "500"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => _cache[26] || (_cache[26] = [
                  _createTextVNode("Confirmar Eliminación")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => _cache[27] || (_cache[27] = [
                  _createTextVNode("¿Estás seguro de que deseas eliminar esta persona?")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "green darken-1",
                    text: "",
                    onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.showDeleteDialog = false))
                  }, {
                    default: _withCtx(() => _cache[28] || (_cache[28] = [
                      _createTextVNode("Cancelar")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "red darken-1",
                    text: "",
                    onClick: _ctx.confirmDelete
                  }, {
                    default: _withCtx(() => _cache[29] || (_cache[29] = [
                      _createTextVNode("Eliminar")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}