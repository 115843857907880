<template>
  <v-container>
    <v-banner color="warning" lines="one">
      <template v-slot:text class="app_bar_title"><span class="text-h6 text-red"> Control de Personal</span> </template>

      <template v-slot:actions>
        <v-btn color="red" @click="goToAddPerson" variant="tonal">
          Agregar nuevo
        </v-btn>
      </template>
    </v-banner>
    <v-data-table :sort-by="[{ key: 'code', order: 'asc' }]" :headers="headers" :items="people" class="elevation-0">
      <template v-slot:item.photo="{ item }">
        <v-avatar>
          <v-img v-if="item.photo" :alt="item.name" :src="getUserPhotoUrl(item.photo.filename)"></v-img>
          <span v-else>No Photo</span>
        </v-avatar>
      </template>
      <template v-slot:item.name="{ item }">
        <span>{{ item.name.split(' ')[0] }} {{ item.last_name }}</span>
      </template>
      <template v-slot:item.number_document="{ item }">
        <span>{{ item.typeDocument.code }} - {{ item.number_document }}</span>
      </template>
      <template v-slot:item.options="{ item }">
        <div class="d-flex align-center">
          <v-btn elevation="0" size="small" variant="tonal" color="primary" v-if="item.id" @click="generateQR(item.id)">QR</v-btn>
          <v-btn elevation="0" size="small" variant="tonal" color="secondary" v-if="item.id" @click="editPerson(item.id)">Editar</v-btn>
          <v-btn elevation="0" size="small" variant="tonal" color="success" v-if="item.id" @click="downloadCarnet(item)">Descargar Carnet</v-btn>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip size="small" :color="item.status ? 'green' : 'red'" variant="flat">
          {{ item.status ? 'Activo' : 'Inactivo' }}
        </v-chip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import api from "@/services/api";
import { useRouter } from "vue-router";
import { Person } from "@/types"; // Importa la interfaz Person
import QRCode from 'qrcode';

export default defineComponent({
  name: "ControlPersonas",
  setup() {
    const router = useRouter();
    const people = ref<Person[]>([]); // Usa la interfaz Person para tipar el array people
    const headers = [
      { title: "Nombre", value: "name" },
      { title: "Documento", value: "number_document" },
      { title: "Foto", value: "photo" },
      { title: "Tipo de Sangre", value: "bloodType.code" },
      { title: "Codigo", value: "code" },
      { title: "Cargo", value: "jobTitle.name" },
      { title: "Estado", value: "status" },
      { title: "Opciones", value: "options" },
    ];

    const fetchPeople = async () => {
      try {
        const response = await api.get("/peoples");
        people.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const getUserPhotoUrl = (filename: any) => {
      const baseUrl = process.env.VUE_APP_API_URL || 'https://api.v1.funaprodedh.com';
      return `${baseUrl}uploads/${filename}`;
    };

    const goToAddPerson = () => {
      router.push("/dashboard/add-person");
    };

    const generateQR = async (id: string) => {
      try {
        const url = await QRCode.toDataURL(`https://system.v1.funaprodedh.com/person/${id}`);
        const link = document.createElement('a');
        link.href = url;
        link.download = `QR_${id}.png`;
        link.click();
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    };

    const editPerson = (id: string) => {
      router.push(`/dashboard/edit-person/${id}`);
    };

    const formatDocumentNumber = (number: string) => {
      return number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const downloadCarnet = async (item: Person) => {
      try {
        const formattedDocumentNumber = formatDocumentNumber(item.number_document);
        const data = {
          frontData: {
            foto1: getUserPhotoUrl(item.photo.filename),
            foto2: getUserPhotoUrl(item.photo.filename),
            apellidos: `${item.last_name.toUpperCase()} ${item.mother_lastname.toUpperCase()}`,
            nombres: item.name.toUpperCase(),
            cedula: `${item.typeDocument.code.toUpperCase()}. V-${formattedDocumentNumber}`,
            cargo: item.jobTitle.name.toUpperCase(),
            codigo: `CODIGO: ${item.code.toUpperCase()}`
          },
          backData: {
            foto1: `https://system.v1.funaprodedh.com/person/${item.id}`,
            sangre: `GRUPO SANGUINEO: ${item.bloodType.code.toUpperCase()}`,
            vencimiento: "VENCIMIENTO 31-12-2025",
            telefonos: item.phones.map(phone => phone.phone).join(' / '),
            correos: item.mails.map(mail => mail.address.toLowerCase()).join(' / ')
          }
        };

        const response = await api.post('/pptx/generar-carnet', data, {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `CARNET_${item.name.toUpperCase()}_${item.last_name.toUpperCase()}_${item.mother_lastname.toUpperCase()}_CODIGO_${item.code.toUpperCase()}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading carnet:', error);
      }
    };

    onMounted(fetchPeople);

    return {
      people,
      headers,
      goToAddPerson,
      getUserPhotoUrl,
      generateQR,
      editPerson,
      downloadCarnet,
    };
  },
});
</script>